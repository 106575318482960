.ant-form-item {
  margin-bottom: 8px;
}

.ant-table-placeholder {
  padding: 8px 8px;
}

/* Disable all antd modal animation for now.
 * TODO(Zangr) disalble dropdown animation
 * This approach is suggested by antd team, more info available in the link below:
 * https://github.com/ant-design/ant-design/issues/951
 */
.ant-modal-wrap div, .ant-modal-mask {
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

/* Ant design overall animation override should not affect fontawesome */
.fa {
  -webkit-animation-duration: 2s !important;
          animation-duration: 2s !important;
}

.ant-dropdown-menu-item:hover,
.ant-select-dropdown .ant-select-dropdown-menu-item:hover,
.ant-select-dropdown .ant-select-dropdown-menu-item:active,
.ant-select-dropdown .ant-select-dropdown-menu-item-active {
  background-image: none;
  background-color: rgb(1, 147, 225);
  color: #ffffff;
}

.ant-dropdown-menu-item, .ant-select-dropdown .ant-select-dropdown-menu-item {
  transition: all 0s;
}

.ant-table-placeholder {
  z-index: 0;
}

.ant-collapse-borderless {
  background-color: white;
}

.mde-text {
  border: 2px solid #0092E1;
}

.editable-note-actions {
  margin-top: 16px;
}

.editable-note-actions button + button {
  margin-left: 16px;
}

.collapsible-section {
  margin-left: -16px;
}

.collapsible-section.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: inline-block; /* restrict toggleable area */
  font-size: 16px;
  padding-left: 36px;
  padding-right: 0;
}

.collapsible-section .ant-collapse-item {
  border: 0;
  overflow: hidden;
}



.model-view-content .table-name {
  font-size: 18px;
}

.model-version-table-header {
  display: flex;
}

.active-toggle {
  margin-left: 10px;
}

.ModelView-run-buttons{
  margin-top: 4px;
}

.ModelView-run-buttons .btn {
  margin-left: 16px;
}

.rm-breadcrumb-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rm-header {
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: bold;
  color: #333;
  display:flex;
  align-items: center;
}

.breadcrumb-link {
  color: inherit;
}

.breadcrumb-chevron {
  margin-top: 5px;
}

.emailNotificationPreferenceDropdownWrapper {
  margin-left: auto;
}

.email-notifications-mark {
  padding-right: 8px;
  padding-left: 8px;
  color: #849298;
}

.subheading-text {
  color: #849298;
}

.model-version-table {
    margin-bottom: 25px;
}

.model-version-table .model-version-link {
    cursor: pointer;
}

.model-version-table .model-version-status-icon {
    font-size: 13px;
}

/* >>> Extract to our own Alert wrapper component */
.status-alert {
    margin-bottom: 16px;
    border-radius: 2px;
}

.status-alert .model-version-status-icon {
    margin-left: -3px;
}

.status-alert.status-alert-info .icon-pending {
    color: green;
    margin-right: 4px;
}

.status-alert.status-alert-info {
    border-left: 2px solid #3895d3;
}

.status-alert.status-alert-error {
    border-left: 2px solid red;
}


.breadcrumb-header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.breadcrumb-header {
    font-size: 18px;
    margin-bottom: 24px;
    font-weight: bold;
    color: #333;
    display:flex;
    align-items: center;
    margin-right: 10px;
}

.version-follow-icon {
    margin-left: auto;
}

.ant-popover-content {
    max-width: 500px;
}

.breadcrumb-link {
    color: inherit;
}
/* <<< Extract to our own Alert wrapper component */

.stage-transition-dropdown .ant-tag {
    cursor: pointer;
}

/** TODO(Zangr) migrate globally common components and styles into src/common folder */
.metadata-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.metadata-entry {
  margin-bottom: 16px;
}

.breadcrumb-caret {
  font-size: 11px;
  vertical-align: 4px;
  padding-left: 5px;
  cursor: pointer;
}

.icon-pending, .icon-ready {
  color: green;
}

.icon-fail {
  color: red;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-size: 14px;
}

input[type=text], textarea {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

::-moz-placeholder {
  opacity: 0.6;
}

:-ms-input-placeholder {
  opacity: 0.6;
}

::placeholder {
  opacity: 0.6;
}

button, .btn {
  font-size: 14px;
  background-color: #f5f5f5;
  border-color: #cccccc;
}

button:hover, .btn:hover {
  border-color: #c0c0c0;
}

a {
  color: #017fcb;
}

a:hover, a:focus {
  color: #015693;
}

.btn-primary {
  background-color: #0193e1;
  border-color: #0193e1;
}

.btn-primary:hover {
  background-color: #017fcb;
  border-color: #017fcb;
}

.btn-primary[disabled], .btn-primary[disabled]:hover {
  background-color: #0193e1;
  border-color: #0193e1;
  opacity: 0.4;
}

.App-header {
  background-color: #082142;
  height: 60px;
  color: white;
  display: block;
  margin-bottom: 24px;
}

.App-header-text {
  position: relative;
  top: 40%;
}

.App-experiments {
  width: 200px;
}

.App-content {
  width: calc(100% - 128px);
  margin: 0 64px 0 64px;
}

div.mlflow-logo {
  display: flex;
  float: left;
}

img.mlflow-logo {
  height: 40px;
  margin-left: 64px;
  margin-top: 10px;
  margin-bottom: 10px;
}

div.github {
  padding-right: 24px;
  color: #ffffff;
}

div.docs {
  color: #ffffff;
}

.header-nav-link {
  height: 40px;
}

.header-nav-link:link {
  text-decoration: none;
}

.header-nav-link-models {
  margin-left: 24px;
}

div.experiments, div.models {
  color: #ffffff;
}

div.header-links {
  display: flex;
  float: right;
  padding-top: 20px;
  margin-right: 64px;
  font-size: 16px;
}

div.header-route-links {
  display: flex;
  float: left;
  padding-top: 20px;
  margin-left: 40px;
  font-size: 16px;
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

h1 a, h1 a:hover, h1 a:active, h1 a:visited {
  color: #333;
}

h2 {
  font-size: 16px;
  font-weight: normal;
  color: #333;
}

h3 {
  font-size: 16px;
  font-weight: normal;
  color: #333;
}

label {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  margin: 0;
}

span.metadata {
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  margin-right: 100px;
}

span.metadata-header {
  font-size: 14px;
  font-weight: normal;
  color: #888;
  margin-right: 4px;
}

table th {
  background-color: #fafafa;
  color: #888888;
  font-weight: 500;
}

/* Overrides for bootstrap modal */
.modal-body {
  padding: 16px;
  padding-bottom: 8px;
}

.modal-footer {
  border-top: 0px;
  padding-top: 0px;
}

.modal-footer .btn+.btn {
  margin-left: 16px;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single-line {
  height: 1.3em;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img.center {
  display: block;
  margin: 0 auto;
}

.plotly-notifier, .plotly-notifier.notifier-note {
  font-family: inherit !important;
  font-size: 13px !important;
}

.RequestStateWrapper-error {
  width: auto;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.Spinner {
    width: 100px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.Spinner img {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin:-40px 0 0 -40px;
    -webkit-animation: spin 3s linear 0.5s infinite;
    animation: spin 3s linear 0.5s infinite;
}

.Spinner.Spinner-immediate img {
    animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
}

@-webkit-keyframes spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: rotate(360deg);
    }
}

/* BEGIN Header stuff. */
.experiment-name {
  display: inline-block;
}

.header-container {
  display: flex;
}

.separator {
  font-size: 16px;
  margin-left: 12px;
  margin-right: 12px;
}

.run-uuid {
  display: inline-block;
}
/* END Header stuff. */

/* BEGIN run-info stuff. */
.run-info-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.run-info {
  min-width: 450px;
  margin-bottom: 8px;
  margin-right: 12px;
}

.metadata-header {
  font-size: 14px;
  color: #888;
}
.metadata-info {
  font-size: 14px;
  color: #333;
}

/* END run-info stuff. */

/* BEGIN table styling stuff */
.table-name {
  display: block;
  padding-top: 6px;
  cursor: pointer;
}

i.expander {
  margin-right: 8px;
}
/* END table styling stuff */



.RunView-label {
  font-weight: bold;
}

.RunView-metadata {
  padding: 5px;
}

.RunView-metricArea {
  margin-left: 400px;
}

.RunView-info {
  padding-right: 10px;
}

textarea.run-command {
  width: 100%;
  font-family: Menlo, Consolas, monospace;
}

.header-container .mlflow-menu > li.mlflow-menu-item:hover {
  background-image: none;
  background-color: rgb(1, 147, 225);
  color: #ffffff;
}

.mlflow-menu .mlflow-menu-item > a:hover {
  background-color: rgb(1, 147, 225);
  color: #ffffff;
}

/** Menus in a header have negative margin-top to account for the header's margin-bottom */
.mlflow-menu.header-menu {
  margin-top: -22px;
}

.mlflow-dropdown {
  margin-top: -5px;
}

.rename-run-form {
  width: 480px;
}

.header-container .mlflow-dropdown .mlflow-dropdown-button,
.header-container .mlflow-dropdown .mlflow-dropdown-button:hover,
.header-container .mlflow-dropdown .mlflow-dropdown-button:active,
.header-container .mlflow-dropdown .mlflow-dropdown-button:focus {
    background-color: transparent;
    border-color: transparent;
    border: none;
    box-shadow: none;
    background-image: none;
}

.modal-close-link, .modal-close-link:hover, .modal-close-link:visited {
  position: absolute;
  top: 12px;
  right: 16px;
  color: black;
}

.modal-close-link:hover {
  cursor: pointer
}

.rename-run-form .mlflow-save-button {
  margin-left: 16px;
}

.mlflow-form-button {
  padding-left: 16px;
  padding-right: 16px;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

th {
  background-color: #fafafa;
  font-size: 14px;
  color: #888;
}

.model-select-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.model-select-dropdown .create-new-model-option {
  border-top: 1px solid #ccc;
}

.register-model-form .modal-explanatory-text {
  color: rgba(0, 0, 0, 0.52);
  font-size: 13px;
}

.image-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
  padding-left: 16px;
}
.image-container {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.ShowArtifactPage .text-area {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: Menlo, Consolas, monospace;
}

.ShowArtifactPage .text-area-border-box {
  width: 100%;
  height: 500px;
}
.map-container {
  height: 500px;
  width: 100%;
}

.leaflet-container {
  height: 500px;
  width: 100%;
}

.html-iframe {
  border: none;
}

.pdf-outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    padding-left: 16px;
}
.pdf-viewer {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
}

.paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 1001;
    top: 0;
    padding-bottom: 15px;
    background-color: rgba(250,250,250,0.6);
    padding-top: 10px;
}

.document {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styles for antd `copyable` code snippets */

.code-keyword { color: rgb(204,120,50); }
.code { color: rgb(100,110,120); }
.code-comment { color: rgb(140, 140, 140); }
.code-string { color: rgb(106,165,89); }
.code-number { color: rgb(104,151,187); }

.serving-call-model-container .ant-typography-copy {
    position: absolute;
    right: 16px;
    top: 16px;
}

.artifact-logged-model-view-code-content .ant-typography-copy {
    position: absolute;
    right: 10px;
    top: 30px;
}

div.select-preview-outer-container {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.select-preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10%;
}

span.select-preview-header {
  font-size: 24px;
  display: block;
}

span.select-preview-supported-formats {
  font-size: 16px;
  padding-top: 8px;
  color: #888888;
  display: block;
}

div.select-preview-image-container {
  height: 54px;
  width: 52px;
  margin-right: 24px;
}

img.select-preview-image {
  height: 54px;
  width: 52px;
}

div.select-preview-text {
  vertical-align: middle;
}

.artifact-info {
  white-space: nowrap;
}

div.artifact-view {
  height: 690px;
  border: solid 1px #ccc;
  display: flex;
  overflow-x: scroll;
}

.artifact-left {
  min-width: 200px;
  max-width: 400px;
  flex: 1 1;
}

.artifact-left li {
  white-space: nowrap;
}

.artifact-right {
  flex: 3 1;
  min-width: 400px;
  max-width: calc(100% - 200px); /* 200px is the min-width of .artifact-left */
}

.artifact-info {
  height: 56px;
  background-color: #fafafa;
  padding: 8px 16px;
  white-space: nowrap;
  display: flex;
}

.artifact-info-left {
  flex: 1 1;
  max-width: 75%;
}
.artifact-info-right {
  margin-left: auto;
}

.artifact-info-path {
  display: flex;
}

.artifact-info-text {
  min-width: 0;
}

.artifact-info-link {
  height: 40px;
  padding-top: 5px;
  font-size: 21px;
}

.artifact-info-size {
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-button {
  margin-top: 6px;
}

.empty-artifact-outer-container {
  height: 558px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ccc;
  background-color: #fafafa;
}

.empty-artifact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-artifacts {
  width: 255px;
  font-size: 24px;
  margin: 0 auto;
}

.no-artifacts-info {
  font-size: 16px;
  padding-top: 8px;
  color: #888888;
}

.loading-spinner {
  height: 20px;
  opacity: 0;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.artifact-info-right .model-version-link {
  display: flex;
  align-items: baseline;
  max-width: 140px;
  padding-top: 1px;
  padding-left: 4px;
}

.artifact-info-right .model-version-link .model-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.artifact-info-right .model-version-info {
  font-size: 12px;
}

.artifact-info-right .model-version-info .model-version-link-section {
  display: flex;
  align-items: center;
}

.artifact-info-right .model-version-info .model-version-status-text {
  overflow: hidden;
  max-width: 160px;
  text-overflow: ellipsis;
}

.artifact-load-error-outer-container {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ccc;
  background-color: #fafafa;
}

.artifact-load-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%
}

.artifact-load-error-header {
  width: 255px;
  font-size: 24px;
  margin: 0 auto;
}

.artifact-load-error-info {
  font-size: 16px;
  padding-top: 8px;
  color: #6B6B6B;
}

.artifact-load-error-icon {
  color: #DB1905;
  margin-right: 4px;
}
.editable-table .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}


/* for consistency with other bootstrap based tables, remove after antd take over */
.editable-table .ant-table-thead > tr > th, .editable-table .ant-table-tbody > tr > td {
  border-top: 1px solid #ddd;
  border-bottom: none;
}

/* for consistency with other bootstrap based tables, remove after antd take over */
.editable-table .ant-table-middle > .ant-table-content > .ant-table-body >
table > .ant-table-tbody > tr > td:not(.editing-cell) {
  padding: 8px;
}

/* for consistency with other bootstrap based tables, remove after antd take over */
.editable-table .ant-table-middle > .ant-table-content > .ant-table-body >
table > .ant-table-thead > tr > th {
  padding: 8px;
  color: #888888;
}

/* for consistency with other bootstrap based tables, remove after antd take over */
.editable-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #f5f5f5;
}

.editable-table .ant-table-body {
/* !important to override antd table inline style of overflowY: scroll */
  overflow-y: auto !important;
  overflow-x: hidden;
}

div.MetricView {
  display: block;
  max-height: 10%;
}

div.MetricView h1 {
  margin-bottom: 24px;
}

.metrics-plot-container {
  display: flex;
  width: 100%;
}

.metrics-plot-container .plot-controls .x-axis-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.metrics-plot-container .plot-controls .metrics-select {
  width: 300px;
  display: block;
}

.metrics-plot-container .plot-controls .metrics-select input[type='text'] {
  padding: 0;
}

.metrics-plot-container .plot-controls {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}

.metrics-plot-container .plot-controls .inline-control {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.metrics-plot-container .plot-controls .inline-control .control-label {
  margin-right: 10px;
}

.metrics-plot-container .plot-controls .block-control {
  margin-top: 25px;
}

.metrics-plot-container .metrics-plot-view-container {
  min-height: 500px;
  flex: 1 1;
}

.breadcrumb-title {
  max-width: 500px;
  vertical-align: bottom;
  display: inline-block;
}

.breadcrumb-chevron {
  font-size: 75%;
  margin-left: 10px;
  margin-right: 8px;
  vertical-align: 4px;
}

.compare-table th.row-header {
  min-width: 120px;
}

.compare-table th.inter-title {
  padding: 20px 0 0;
  background: transparent;
}

.compare-table td.data-value,
.compare-table th.data-value {
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
}

.responsive-table-container {
  width: 100%;
  overflow-x: auto;
}

.compare-table tr.row-changed th, .compare-table tr.row-changed td{
  background-color: rgba(249, 237, 190, 0.5) ;
  color: #555;
}

.scatter-plotly {
  width: 100%;
  height: 100%;
  min-height: 35vw;
}

.scatter-plotly {
  width: 100%;
  height: 100%;
  min-height: 35vw;
}

.inline-control {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.inline-control .control-label {
  margin-right: 10px;
}

.parallel-coordinates-plot-panel {
  display: flex;
}

.parallel-coordinates-plot-panel .plot-controls .metrics-select {
  width: 300px;
}

.center {
  text-align: center;
}
.center {
  text-align: center;
}

.error-image {
  margin: 12% auto 60px;
  display: block;
}

.outer-container {
  display: flex;
}
.HomePage-experiment-list-container {
  flex: 0 0 312px;
}
.experiment-view-container {
  /*
   * Allow experiment view container to be smaller than its content (i.e. a large-width runs table)
   * by overriding min-width as described in https://stackoverflow.com/a/38383437 and
   * https://developer.mozilla.org/en-US/docs/Web/CSS/flex
   */
  min-width: 0px;
  flex: 1 1;
  padding: 0 64px 0 32px;
}


/* BEGIN css for when experiment list collapsed */
.experiment-page-container {
  width: 100%;
  padding: 0 64px 0 32px;
}
.collapsed-expander-container {
  float: left;
}

.expander {
  display: inline-block;
  background-color: #082142d6;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}
/* END css for when experiment list collapsed */

.experiment-list-outer-container {
  padding-left: 64px;
}

.experiment-list-container {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 220px;
}

.active-experiment-list-item {
  background: rgba(67, 199, 234, 0.1);
  font-weight: bold;
}

.experiment-list-item {
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
}

.experiment-list-search-input {
  flex: 1 1;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 220px;
  padding-bottom: 6px;
  margin-bottom: 8px;
}

input.experiment-list-search-input[type="text"]:focus{
  border-color: #258BD2;
  outline: none;
}

.experiments-header {
  font-weight: normal;
  display: inline-block;
  margin-bottom: 8px;
}

.collapser-container {
  display: inline-block;
  position: relative;
  top: -2px;
}

.collapser {
  display: inline-block;
  background-color: #082142d6;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  margin-left: 12px;
  cursor: pointer;
}

.experiment-list-create-btn-container {
  display: inline-block;
  position: relative;
  top: -2px;
}

.experiment-list-create-btn {
  width: 24px;
  height: 24px;
  line-height: inherit;
  color: #333333;
  background-color: #f5f5f5;
  border-color: #cccccc;
  border-radius: 4px;
  margin-left: 60px;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}

.ExperimentPage {
  margin-left: auto;
  margin-right: auto;
}

.ExperimentView th {
    background-color: #fafafa;
    color: #888888;
    font-weight: 500;
}

.ExperimentView td, .ExperimentView th {
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
}

.ExperimentView .metadata-list td, .ExperimentView .metadata-list th {
    border: none;
}

.LinkColor {
    color: #2374BB;
}

.ExperimentView .information {
    margin: 0px 0px 16px 0px;
    padding-left: 56px;
    padding-top: 14px;

    background: #EDFAFE;
    /* Gray-background */

    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    /* Dropshadow */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.ExperimentView .ant-alert-info .ant-alert-icon {
    color: #00B379;
}

.ExperimentView .ant-select-selection-selected-value {
    font-size: 14px;
}

.ExperimentView .top-row {
    text-align: center;
    border-bottom: none;
    border-top: none;
}

.ExperimentView .bottom-row {
    text-align: left;
    border-top: none;
}

.ExperimentView .left-border {
    border-left: 1px solid #e2e2e2;
}

.ExperimentView-run-buttons .btn {
    margin-left: 16px;
}

.ExperimentView-run-buttons .run-count {
    font-size: 14px;
    color: #6B6B6B;
}

.ExperimentView-evenRow {
    background-color: #bbbbbb;
}

.ExperimentView-evenRow:hover {
    background-color: #acacac;
}

.ExperimentView-oddRow:hover {
    background-color: #e1e1e1;
}

.ExperimentView-downloadCsv {
    float: right;
}

.ExperimentView-search-controls {
    margin-top: 8px;
    min-width: 800px;
}

.ExperimentView-info .note-view-outer-container {
    width: 100%;
    height: auto;
}

.ExperimentView-info .note-editor-button-area {
    padding-bottom: 12px;
}

.ExperimentView-run-buttons {
    margin-top: 16px;
    margin-bottom: 16px;
}

.ExperimentView-paramKeyFilter, .ExperimentView-metricKeyFilter {
    display: inline-block;
    width: 50%;
    min-width: 210px;
    margin-top: 8px;
}

.ExperimentView-search {
    display: flex;
    align-items: center;
}

.ExperimentView-search-input {
    flex: 1 1;
    width: auto;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
}

.ExperimentView-search-help {
    margin-right: 10px;
    cursor: pointer;
}

.ExperimentView-lifecycle-input {
    padding-top: 8px;
}

.ExperimentView-paramKeyFilter, .ExperimentView-metricKeyFilter, .ExperimentView-search-input, .ExperimentView-lifecycle-input {
    padding-right: 8px;
}

.ExperimentView-search-buttons .btn {
    display: block;
    width: 100%;
    margin-bottom: 8px;
}

.ExperimentView-search-controls .filter-label {
    width: 92px;
    float: left;
    margin-top: 6px;
}

.ExperimentView-search-controls .filter-wrapper {
    margin-left: 92px;
}

.ExperimentView-search-controls input {
    width: 100%;
}

.search-button {
    width: 120px;
    height: auto;
    margin-left: 10px;
}

.clear-button {
    margin-left: 10px;
    height: auto;
}

.filter-button {
    margin-left: 10px;
    height: 40px;
    width: 97px;
}

.compare-button {
    margin-left: 4px;
}

.csv-button {
    margin-left: 4px;
}

.delete-restore-button {
    margin-left: 4px;
}

.model-versions-label {
    padding-left: 20px;
}

.filterIcon {
    padding-right: 8px;
}

.search-control-btns {
    display: flex;
}

.lifecycleButtons-enter {
    opacity: 0.01;
}

.lifecycleButtons-enter.lifecycleButtons-enter-active {
    opacity: 1;
    transition: opacity 500ms linear;
}

.lifecycleButtons-exit {
    opacity: 1;
}

.lifecycleButtons-exit.lifecycleButtons-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.ExperimentView-linked-model-button {
    margin-left: 10px;
}

.ExperimentView-lifecycle-button {
    margin-left: 10px;
}

div.error-message {
    margin-top: 8px;
    margin-left: 0;
    /*width: auto;*/
}

span.error-message {
    color: #db1905;
}

.metric-filler-bg {
    position: relative;
    background-color: #f7f7f7;
    width: 50px;
    display: inline-block;
}

.metric-filler-fg {
    background-color: #def1ff;
    position: absolute;
    top: -1px;
    height: 22px;
    display: inline-block;
}

.metric-text {
    position: relative;
}

.ExperimentView .sortable {
    white-space: nowrap;
    cursor: pointer;

    /* Prevent double click text select */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.ExperimentView .metric-param-sort-toggle {
    color: #888;
    cursor: pointer;
}

.ExperimentView .underline-on-hover:hover {
    text-decoration: underline;
}

.ExperimentView .metric-param-value {
    margin-left: 4px;
}

.ExperimentView .highlighted {
    border-radius: 5px;
    background-color: rgba(255, 242, 172, 0.5);
    color: black;
}

/** Container with a max width & text-truncation for use in the experiment runs table */
.ExperimentView .run-table-container {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
    height: 100%;
}

.ExperimentView .metric-param-container-cell {
    min-width: 280px;
    padding: 8px;
}

.ExperimentView .metric-param-cell {
    display: inline-block;
    width: 250px;
    padding: 0;
}

.ExperimentView .tag-cell-item {
    height: 100%;
}

.ExperimentView-expander:hover {
    cursor: pointer;
}

.ExperimentView .ReactVirtualized__Table__headerRow {
    overflow: visible;
    text-transform: inherit;
    font-weight: inherit;
}

.ExperimentView .ReactVirtualized__Table__Grid:focus {
    outline: 0;
}

/* Workaround as per https://github.com/bvaughn/react-virtualized/issues/876#issuecomment-367029529 */
.ExperimentView .ReactVirtualized__Table__rowColumn {
    overflow: visible !important;
}

.ExperimentView .ReactVirtualized__Table__headerColumn {
    color: rgb(136, 136, 136);
}

.ExperimentView .ReactVirtualized__Table .run-table-container {
    padding: 8px;
}

.ExperimentView .ReactVirtualized__Table .run-table-container.metric-param-sort-toggle {
    padding: 0;
}

.ExperimentView .ReactVirtualized__Table .run-table-container.metric-param-value {
    padding: 0;
}

/**
 * Style for ancestor elements of the runs table. Arranges its children along a vertical axis
 * via (flex-direction: column). If the parent element has 'display: flex' set, will fill up
 * 100% of the available vertical space (via 'flex: 1 1 auto')
 */
.runs-table-flex-container {
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
    min-height: 800px;
}

.ExperimentView .ReactVirtualized__Table__row:hover {
    background-color: #f5f5f5;
}

.search-input-tooltip .ant-popover-inner-content {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 4px;
}

.search-input-tooltip.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    border-top-color: rgba(0, 0, 0, 0.75);
    border-left-color: rgba(0, 0, 0, 0.75);
}

.artifact-location-public-tooltip .ant-popover-inner-content {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 4px;
}

.artifact-location-public-tooltip.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    border-top-color: rgba(0, 0, 0, 0.75);
    border-left-color: rgba(0, 0, 0, 0.75);
}

.ExperimentView .sort-select {
    width: 200px;
}

.ExperimentView .start-time-select {
    width: 150px;
}

.ExperimentView .ag-row-hover, .ExperimentView .ag-column-hover {
    background-color: #f3fafd;
}

.ExperimentView .ag-row-hover .ag-column-hover {
    background-color: #e8f6fd;
}

.ExperimentView .multi-column-view .load-more-button,
.ExperimentView .multi-column-view .loading-more-wrapper {
    height: 24px;
    margin-top: 1px;
}

.ExperimentView .ag-header-cell .ag-react-container {
    width: 100%;
    height: 100%;
}

.ExperimentView .multi-column-view {
    width: 100%;
    height: 700px;
    margin-bottom: 50px;
}

.ExperimentView .ag-overlay-wrapper {
    margin-top: 50px;
    align-items: start;
}

/* Removing the padding from the parent cell being added and 
   explicitly specifying the header with the padding.
   Without this, the sorting selection on the header would 
   be padded because of the parent padding. */
.ExperimentView .ag-theme-balham .ag-header-cell {
    padding: 0;
}

/* Adding the padding back on the checkbox, 
   since the checkbox doesn't use the custom header where we added 
   the padding which we removed from other columns above. */
.ExperimentView .ag-header-cell .ag-checkbox {
    padding: 0 12px
}

.ExperimentView .version-link {
    display: flex;
    align-items: center;
}

.ExperimentView .model-link-text {
    padding-left: 3px;
}

.breadcrumb-caret {
    font-size: 11px;
    vertical-align: 4px;
    padding-left: 5px;
    cursor: pointer;
}
  
.breadcrumb-caret-hidden {
    font-size: 11px;
    vertical-align: 4px;
    padding-left: 5px;
    cursor: pointer;
    visibility: hidden;
}

.pagination-section {
    padding-bottom: 30px;
}

.pagination-dropdown .ant-dropdown-menu-item {
    text-align: center;
}

.onboarding-information {
    margin: 0px 0px 16px 0px;
    padding-left: 56px;
    padding-top: 14px;

    background: #EDFAFE;
    /* Gray-background */

    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    /* Dropshadow */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.ant-alert-info .ant-alert-icon {
    color: #00B379;
}

.table-tag-container {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
}

.ModelListView-filter-dropdown {
    margin-top: 0;
    min-width: 800px;
    margin-bottom: 24px;
}

